import { useIsDesktop } from "@/common/MediaQueries";
import colors from "@/theme/colors";
import sizes from "@/theme/sizes";
import theme from "@/theme/theme";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Image from "next/image";
import Link from "next/link";
import { Pages } from "../../common/pages.modal";

const StyledHeaderWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  zIndex: 1,
  justifyContent: "space-between",
  alignItems: "center",
  padding: sizes.header.paddingHeader,
  height: sizes.header.headerHeight,
  backgroundColor: colors.black,
  borderBottom: `1px solid ${colors.gray}`,
}));

const StyledButton = styled(Button)(() => ({
  minWidth: sizes.button.rounded.sm.width,
  height: sizes.button.rounded.sm.height,
  borderRadius: sizes.button.rounded.sm.borderRadius,
  display: "flex",
  justifyContent: "space-evenly",
  gap: 8,
  flexShrink: 0,
}));

const LogoImageWrapper = styled(Box)(() => ({
  width: "100%",
  maxWidth: sizes.images.logo_sm.width,
  height: "auto",
  marginRight: 10,
  display: "flex",
  justifyContent: "flex-end",
}));

const StyledTypography = styled(Typography)`
  font-size: 18px;
  font-weight: 700;

  ${() => theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`;

const Header = () => {
  const isDesktop = useIsDesktop();
  return (
    <StyledHeaderWrapper>
      <Link
        href="https://sale.electra-power.co.il/#form"
        passHref
        legacyBehavior
      >
        <StyledButton variant="contained" color="primary">
          <Image src="/icon_call.svg" alt="צור קשר" width={24} height={24} />
          <StyledTypography variant="button">תחזרו אליי</StyledTypography>
        </StyledButton>
      </Link>

      <LogoImageWrapper>
        <Link href={`/${Pages.onBoarding}`} aria-label="חזרה לתחילת התהליך">
          <Image
            src="./logo.svg"
            alt="לוגו אלקטרה פוואר"
            width={
              isDesktop
                ? sizes.images.logo_sm.width
                : sizes.images.logo_sm_mobile.width
            }
            height={
              isDesktop
                ? sizes.images.logo_sm.height
                : sizes.images.logo_sm_mobile.height
            }
            style={{
              verticalAlign: "middle",
            }}
          />
        </Link>
      </LogoImageWrapper>
    </StyledHeaderWrapper>
  );
};

export default Header;
