import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";

const useSendHeightToParent = () => {
  let pathname = usePathname();
  let searchParams = useSearchParams();

  useEffect(() => {
    const sendHeightToParent = () => {
      const height = document.body.scrollHeight + 200;
      window.parent.postMessage({ height }, "*"); // Send height to parent
    };
    setTimeout(() => {
      sendHeightToParent();
    }, 300);

    return () => {
      sendHeightToParent();
    };
  }, [pathname, searchParams]);
};

export const SendHeightToParent = () => {
  useSendHeightToParent();
  return null;
};

export default useSendHeightToParent;
